module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-theme-ui@0.15.7_@emotion+react@11.11.4_@types+react@18.3.3_react@18.3.1__@mdx-j_oipsimjoac6gy5tv5bzacupcs4/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_@types+webpack@5.28.5_babel-eslint@10.1.0_eslint@_ybraxdomukeebdxqdqa2g2o3ne/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Portfólio Suelen Zavon","short_name":"Suelen Zavon","description":"Portfólio de Suelen Zavon, uma modelo de moda brasileira de Juiz de Fora. Descubra seu trabalho, projetos, e ensaios fotográficos impressionantes.","start_url":"/","background_color":"#ffffff","display":"standalone","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.6_@types+webpack@5.28.5_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react_m2eephruhlc3iyc7llxtlbrtzi/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
